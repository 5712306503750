<template>
  <v-ons-splitter>
    <v-ons-splitter-side
      width="70%"
      collapse=""
      side="left"
      :open.sync="openSide"
    >
      <v-ons-page>
        <v-ons-card >
          <div style="display:table;margin:0 auto;">
              
            
          
          <img  class="avatar" :src=userProfile.photoURL />
          <v-ons-icon icon="md-upload" @click="this.chooseFile"></v-ons-icon>
          </div>
          <input type="file" class="form-control" id="elemFileUpload" @change=fileUploadChange hidden />
          <v-ons-list modifier="inset">

            <v-ons-list-item tappable @click="this.showPrompt" style="height:30px; min-height: 30px">
              <div  style="color:black;font-size:large;font-weight:bold;text-transform:capitalize;">
             {{ currentUser.displayName }} 
             <!--{{currentUser.displayName}} -->

              </div>
            </v-ons-list-item>
            <v-ons-list-item  style="line-height:1.0">
             <div  style="color:blue;font-size:small;">
                  {{currentUser.email }}
            </div>
              </v-ons-list-item>
          </v-ons-list>
            <v-ons-list>
            <v-ons-list-item @click="RefreshMap()">
              <div class="right">
                <v-ons-icon icon="fa-sync" class="list-item__icon">
                </v-ons-icon>
              </div>
              <div class="center">
                Refresh Map
              </div>
            </v-ons-list-item>
            <v-ons-list-item @click="router.push('/logout')">
            <div class="right">
                <v-ons-icon icon="fa-sign-out-alt" class="list-item__icon">
                </v-ons-icon>
              </div>
              <div class="center">
                Logout
              </div>
            </v-ons-list-item>
            <v-ons-list-item
              @click="
                $ons.notification.toast('TerratonePull Version 2.0.4I', {
                  timeout: 2000,
                })
              "
            >
            <div class="right">
                <v-ons-icon icon="fa-info" class="list-item__icon">
                </v-ons-icon>
              </div>
              <div class="center">
                About
              </div>
            </v-ons-list-item>
          </v-ons-list>
        </v-ons-card>
      </v-ons-page>
    </v-ons-splitter-side>

    <v-ons-splitter-content>
      <v-ons-page id="olmap2">
        <div ref="mapView" class="map-view" id="mapView">
          <!-- Popup -->
          <div ref="popup" class="ol-popup">
            <a @click="popupcloser" class="ol-popup-closer"></a>
            <div class="ol-popup-content" v-html="popupContent"></div>
            <div style="float: left;font-size:20px">
                <i
                  class="fas fa-street-view"
                  style="font-size:20px; padding-left:0px;"
                  
                  @click="showStreetviewPage()"
                ></i>
              </div>
            <div
              style="float: right; font-size: 18px"
              v-bind:class="[
                isBookmarked ? 'fa fa-bookmark' : 'far fa-bookmark',
              ]"
              @click="toggleClass()"
            ></div>
          </div>
        </div>
        <v-ons-speed-dial position="bottom right" direction="up" :open.sync="toggle" @click="toggle=!toggle">
          <v-ons-fab >
            <i :class="[toggle ? 'fa-times' : 'fa-plus', 'fa']"></i>
          </v-ons-fab>
          <v-ons-speed-dial-item>
            <v-ons-icon icon="md-comment" @click="showNoteForm()"></v-ons-icon>
          </v-ons-speed-dial-item>
          <v-ons-speed-dial-item>
            <v-ons-icon
              icon="md-bookmark"
              @click.prevent="router.push('bookmark')"
            ></v-ons-icon>
          </v-ons-speed-dial-item>
        </v-ons-speed-dial>
        <v-ons-button
          modifier="quiet"
          class="mainmenu"
          :visible="true"
          @click="openSide = true"
        >
          <v-ons-icon
            style="color: black"
            size="40px"
            icon="fa-caret-square-right"
          ></v-ons-icon>
        </v-ons-button>
       <v-ons-dialog cancelable :visible.sync="createpostVisible">
          <createpost @closeWin="createpostVisible=false" v-bind:ownerinfo='ownerinfo' v-bind:propinfo='propinfo' v-bind:selectedCentre='selectedCentre'></createpost>
        </v-ons-dialog>
      </v-ons-page>
    </v-ons-splitter-content>
  </v-ons-splitter>
</template>
<script>
import "ol/ol.css";
import { Map, View } from "ol";
import * as olControl from "ol/control";
import * as olProj from "ol/proj";
import "../../node_modules/ol-geocoder/dist/ol-geocoder.min.css";
import Geocoder from "ol-geocoder";
import { Style } from "ol/style";
import { Fill, Stroke, Icon } from "ol/style";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import { Vector } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import Control from "ol/control/Control";
import { circular } from "ol/geom/Polygon";
import { fromLonLat } from "ol/proj";
import { containsCoordinate } from "ol/extent";
import Overlay from "ol/Overlay";
import Select from 'ol/interaction/Select';


import fb from "../firebase";

//for API call
import axios from "axios";

import mapconfig from "../config/mapconfig";


import MapFunctions from "../MapFunctions";
import recentTransactions from "../recent-transactions";

// Import the EventBus we just created.
import EventBus from '../config/event-bus';

import { mapState } from "vuex";

import router from "../router";
import createpost from "../components/createpost";
import state from "../state"


const proxyurl1 = "https://index.laurel.workers.dev/?"; // Cloudflare own proxy, account:alfred_cw_chen@yahoo.ca(B211)
const proxyurl2 = "https://index2.laurel.workers.dev/?";

// For Perry const App_extent=[-13708636.322763294,6318548.145849912,-13706072.212231088,6321196.52292543]
//const App_extent=[-13691698.770829778,6311346.989584832,-13688330.310083484,6312792.094212464] // For Ilya
const App_extent=[-13699577.95101826,6314035.542214253,-13696912.108203745,6317552.69152444] // For Edgar Sung
//Map variables
let map = null;
// Variables for Overlay new
let popup;
export default {
  mixins: [MapFunctions],
  data() {
    return {
      //
      popupContent: "",
      isBookmarked: false,
      toggle: false,
      router: router,
      createpostVisible: false,
      openSide: false,
      ownerinfo:null,
      propinfo:null,
      selectedParcel:null,
      selectedCentre:null,
      name: '',
      lonlat:null,
      selectedAddress:null,
      selectedParcel_id:null,
      bookmarksFeatures:null,
      newPhoto:null
      
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser",  "bookmarks"]),

  },
  watched:{

  },
  components: {
    createpost: createpost,
  },
  methods: {
  chooseFile(){
    this.$ons.notification.alert({
      message:"Please select an image file with 200*200 pixel for best display.",
      callback: function (){
          document.getElementById('elemFileUpload').click()
      }
    })
  },
  fileUploadChange (e) {
    let file = e.target.files[0]
    let that=this
    console.log("file",file)
      let filePic=file   
      let fileSize=file.size; 
       if (fileSize > 1024 * 1024 ){
          that.$ons.notification.alert("File size cannot exceed 1M!");
          return
      }
      var reader = new FileReader();
       reader.readAsDataURL(filePic);
       reader.onload = function (e) {
         var data=e.target.result;
         var image=new Image()
         image.onload=function(e) {
           var width=image.width
           var height=image.height
           console.log("imagesize:",width,height)
           if (width== 200 || height==200) {
              that.newPhoto = file
              that.updateProfile()
           }
           else {
             that.$ons.notification.alert ("Image size should be:200*200!")
              return
           }
         }
          image.src=data
       };


   },
   checkBookmarked:function(){
     var abookmark=null
     console.log("selectedParcel_id",this.selectedParcel_id)
     console.log("bookmarks",this.bookmarks)
     abookmark=this.bookmarks.find(bookmark=>bookmark.data.parcel_id==this.selectedParcel_id)
      console.log("abookmark",abookmark )
       this.isBookmarked=(abookmark)
    },
   showPrompt () {
      let that=this
      this.$ons.notification.prompt({message:'Enter your new user name:',title:'Update Profile',cancelable:true,buttonLabels:['Cancel','OK']})
      .then(function(input) {
          if (input) { 
            console.log("input:",input)
            that.name=input
            that.updateProfile();
          }
      });
    },
    updateProfile() {
      let file=this.newPhoto;
      let extension=file.name.split('.').pop()
      let newfilename=this.currentUser.uid + "." + extension
      let newfile=new File([file],newfilename)
    console.log("newfilename",newfile.name)
      var aDownLoadURL
      var uploadTask=fb.storage.ref().child(`images/${newfile.name}`).put(newfile)
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        }, 
        (error) => {
          // Handle unsuccessful uploads
        }, 
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            aDownLoadURL=downloadURL
            this.userProfile.photoURL=aDownLoadURL
            console.log('userProfile',this.userProfile)
            this.$store.dispatch('updateProfile', this.userProfile)

            this.name = ''
          });
        }
      );

     // {
          //name: this.name !== '' ? this.name : this.userProfile.name,
          //title: this.title !== '' ? this.title : this.userProfile.title
      //  })

      
    },
    RefreshMap() {
      console.log("Refresh Map");
      this.$ons.notification.toast("Refreshing the map...", {
        timeout: 1000,
        animation: "fall",
      });
      map.updateSize();
      map.renderSync();
    },
    toggleClass: function(event) {
      this.isBookmarked = !this.isBookmarked;
      this.updateBookmark()
      
    },
    deleteParcelonBookmarkLayer(bookmarkParcel_id){
      map.getLayers().forEach(function(lyr) {
        console.log(lyr.get('name'));
        if (lyr.get("name") === "Bookmarks") {
          //lyr.getSource().addFeatures([feature])
          console.log("bookmarkParcel_id",bookmarkParcel_id)
          console.log("all features:",lyr.getSource().getFeatures())
          var searchFeature=lyr.getSource().getFeatureById(bookmarkParcel_id)
          console.log("searchFeature",searchFeature)
          lyr.getSource().removeFeature(searchFeature)
        
        }
      })
    },
    updateBookmark(){
      // create bookmark
      console.log("uid",this.currentUser.uid)
      console.log("bookmark List",this.bookmarks)
      var selectedCoordinates=JSON.stringify(this.selectedParcel.geometry.coordinates)
      console.log("coordinates",selectedCoordinates)
      var that=this
      var userRef=fb.usersCollection

      var bookmarkRef=userRef.doc(`${this.currentUser.uid}/9V4ms3lgsmTz4EpyGKAC/bookmark`).collection("bookmarks").where("parcel_id","==",this.selectedParcel_id)
      bookmarkRef
        .get()
        .then( (querySnapshot) => {
           if(querySnapshot.empty)
           // Add
            {
            //userRef.doc(this.currentUser.uid).collection("bookmarks")
              userRef.doc(`${this.currentUser.uid}/9V4ms3lgsmTz4EpyGKAC/bookmark`).collection("bookmarks")
              .add({
                createdOn: new Date(),
                coordinates:selectedCoordinates,
                address:this.selectedAddress,
                pid: this.selectedParcel.properties.PID,
                parcel_id:this.selectedParcel_id,
                lonlat:this.lonlat,
                centre:this.selectedCentre.flatCoordinates
              })
              .then((ref) => {
                var feature = new Feature(
                  new Point(fromLonLat(this.lonlat))
                  )
                  console.log("add single feature polygon coordinates",this.selectedParcel.geometry.coordinates)
                  
                  var polygon = new Polygon(this.selectedParcel.geometry.coordinates);
                  var afeature = new Feature(polygon);
                  afeature.setId(this.selectedParcel_id)

                  map.getLayers().forEach(function(lyr) {
                  console.log(lyr.get('name'));
                  if (lyr.get("name") === "Bookmarks") {
                  //lyr.getSource().addFeatures([feature])
                  lyr.getSource().addFeature(afeature)
                  //lyr.visible=true;

                  }
                })
              })
              .catch((err) => {
                console.log(err);
              });
            } else
            //delete
            {
              console.log("querySnapshot doc id",querySnapshot.docs[0].id)
              //querySnapshot.docs[0].delete()
              // yiBuVpolA9xDgUhFpn2V : document id of team perry
              userRef.doc(`${this.currentUser.uid}/9V4ms3lgsmTz4EpyGKAC/bookmark`).collection("bookmarks")
                          .doc(querySnapshot.docs[0].id)
                          .delete()
              console.log("Parcel ID already bookmarked:",this.selectedParcel_id)
              var aselected_Parcel_id=this.selectedParcel_id
              var select= new Select
              select.getFeatures().clear();
              map.getLayers().forEach(function(lyr) {
                console.log(lyr.get('name'));
                if (lyr.get("name") === "Bookmarks") {
                  //lyr.getSource().addFeatures([feature])
                  console.log("aselected_Parcel_id",aselected_Parcel_id)
                  console.log("all features:",lyr.getSource().getFeatures())
                  var searchFeature=lyr.getSource().getFeatureById(aselected_Parcel_id)
                  console.log("searchFeature",searchFeature)
                  lyr.getSource().removeFeature(searchFeature)
                
                }
              })
           } 
        });

    },
    showStreetviewPage () {
      state.lonlat=this.lonlat
      //console.log("lonlat in olmap2",this.lonlat)
      router.push('streetview')
    },
    showNoteForm() {
      this.createpostVisible = true;
    },
    init() {
      this.initMap();
      let that=this
            //this.setupBookmarkLayer()
      map.getLayers().forEach(function(lyr) {
          //console.log(lyr.get('name'));
          if (lyr.get("name") === "Bookmarks") {
               //console.log("that.currentUser",that.currentUser)
               fb.usersCollection.doc(`${that.currentUser.uid}/9V4ms3lgsmTz4EpyGKAC/bookmark`).collection("bookmarks").get()
              .then(res => {
                //console.log("bookmarks in olmap2",res)
                  res.forEach(function (doc) {
                    //console.log("doc.data.coordinates",JSON.parse(doc.data().coordinates))
                    var polygon = new Polygon(JSON.parse(doc.data().coordinates));
                    //console.log("polygon",polygon)
                    var afeature = new Feature(polygon);
                    console.log("data parcel_id",doc.data().parcel_id)
                    afeature.setId(doc.data().parcel_id)
                    console.log("afeature",afeature)
                    lyr.getSource().addFeature(afeature)
                  });
              })
          }
      })
      this.initPopup();
      //router.push({ name: "transaction" });
    },
    initMap() {
      console.log("map");
      map = new Map({
        target: this.$refs.mapView,
        layers: [
          //mapconfig.streetmap,
          mapconfig.googlemap,
          mapconfig.satellitemap,
          mapconfig.bcparcelmap,
          mapconfig.bookmarksmap
          // mapconfig.indmap
        ],
        view: new View({
          center: olProj.transform(
            [mapconfig.x, mapconfig.y],
            "EPSG:4326",
            "EPSG:3857"
          ),
          zoom: mapconfig.zoom,
        }),
        controls: olControl.defaults({
          // Set to display OSM attributions on the bottom right control
          attributionOptions: {
            collapsible: true,
            collapsed: true,
          },
          zoom: false,
        }),
      });



      function ToggleSatLayer() {
        map.getLayers().forEach(function(lyr) {
          // console.log(lyr.get('name'));
          if (lyr.get("name") === "GSatellite") {
            var is_visible = lyr.get("visible");
            lyr.setVisible(!is_visible);
          }
        });
      }
      // Satellite Toggle Button
      const satellite = document.createElement("div");
      satellite.className = "ol-control ol-unselectable satellite";
      satellite.innerHTML = '<button><i class="fa fa-satellite"></i></button>';
      satellite.addEventListener("click", function satelliteclick() {
        ToggleSatLayer();
      });

      map.addControl(
        new Control({
          element: satellite,
        })
      );
      function ToggleBkLayer() {
        map.getLayers().forEach(function(lyr) {
          // console.log(lyr.get('name'));
          if (lyr.get("name") === "Bookmarks") {
            var is_visible = lyr.get("visible");
            lyr.setVisible(!is_visible);
          }
        });
      }
      // Bookmark Toggle Button
      const bookmark = document.createElement("div");
      bookmark.className = "ol-control ol-unselectable bookmark";
      bookmark.innerHTML = '<button><i class="fa fa-bookmark"></i></button>';
      bookmark.addEventListener("click", function bookmarkclick() {
        ToggleBkLayer();
      });

      map.addControl(
        new Control({
          element: bookmark,
        })
      );
      if (navigator.geolocation){
          //navigator.permissions.query({name:'geolocation'}).then(function(result){
          //  console.log("geolocation permission:",result.state)
          //})


          const locate = document.createElement("div");
          locate.className = "ol-control ol-unselectable locate";
          locate.innerHTML =
            '<button><i class="fa fa-location-arrow"></i></button>';
          locate.addEventListener("click", function locateclick() {
                          // For Location Checking
          const source = new Vector();
          const layer = new VectorLayer({
            source: source,
            visible: false,
            zoom: 17,
          });
          map.addLayer(layer);
          navigator.geolocation.getCurrentPosition(
            function(pos) {
              const coords = [pos.coords.longitude, pos.coords.latitude];
              source.clear(true);
              source.addFeatures([
                new Feature(new Point(fromLonLat(coords))),
              ]);
              map.getView().fit(source.getExtent(), {
                maxZoom: 17,
                duration: 500,
              });
            },
            function(error) {
              alert(`ERROR: ${error.message}`);
            },
            {
              enableHighAccuracy: true,
            }
          );
          });
          map.addControl(
            new Control({
              element: locate,
            })
          );
      }

      
      //  Add Geocoder
      var provider = BCAddressFind({
        url: "https://geocoder.api.gov.bc.ca/addresses.geojson",
      });

      //Instantiate with some options and add the Control
      var geocoder = new Geocoder("nominatim", {
        provider: provider,
        targetType: "text-input",
        lang: "en-US",
        placeholder: "Search by address",
        limit: 8,
        autoComplete: true,
        autoCompleteMinLength: 8,
        keepOpen: false,
        preventDefault: false,
        deug: true,
      });

      map.addControl(geocoder);

      recentTransactions.createLayer(map, "mapView", "recentTrans", "recentTrans-filter");
      
      /**
       * Custom provider for BC Address for full address search of British Columbia.
       * Factory function which returns an object with the methods getParameters
       * and handleResponse called by the Geocoder
       */
      function BCAddressFind(options) {
        var url = proxyurl2 + options.url;
        return {
          /**
           * Get the url, query string parameters and optional JSONP callback
           * name to be used to perform a search.
           * @param {object} options Options object with query, key, lang,
           * countrycodes and limit properties.
           * @return {object} Parameters for search request
           */
          getParameters: function(opt) {
            return {
              url: url,
              //callbackName: 'callback',
              params: {
                //  q: opt.query,
                addressString: opt.query,
              },
            };
          },
          /**
           * Given the results of performing a search return an array of results
           * @param {object} data returned following a search request
           * @return {Array} Array of search results
           */
          handleResponse: function(results) {
            // The API returns a GeoJSON FeatureCollection
            if (results && results.features && results.features.length) {
              return results.features.map(function(feature) {
                //     console.log(results);

                return {
                  lon: feature.geometry.coordinates[0],
                  lat: feature.geometry.coordinates[1],
                  address: {
                    // Simply return a name in this case, could also return road,
                    // building, house_number, city, town, village, state,
                    // country
                    name: feature.properties.fullAddress,
                  },
                  bbox: feature.bbox,
                };
              });
            } else {
              return;
            }
          },
        };
      }
      let that = this;
      //Listen when an address is chosen
      geocoder.on("addresschosen", function(evt) {
        /* eslint-disable no-console */
        console.log("geocoder:" + evt.address.formatted);
        /* eslint-enable no-console */
        //geocoderSource.clear();
        //geocoderSource.addFeature(evt.feature); // add only the last one
        //BySearch = true;
        //GetInfoFromSelected(evt.coordinate);

        that.MapOnClick(evt.coordinate);
        function remove_search_pin() {
          var remove_layer_name = "geocoder-layer";
          var layers_to_remove = [];
          map.getLayers().forEach(function(layer) {
            var layer_name = layer.getProperties().name;
            if (layer_name && layer_name.match(remove_layer_name)) {
              layers_to_remove.push(layer);
            }
          });
          for (var i = 0; i < layers_to_remove.length; i++) {
            map.removeLayer(layers_to_remove[i]);
          }
        }
        remove_search_pin();
      });
      map.on("singleclick", (evt) => {
        var clickedFeature = map.forEachFeatureAtPixel(
          evt.pixel,
          function(feature) {return feature;}
        );
        if (clickedFeature && clickedFeature.featureType) {
          switch (clickedFeature.featureType) {
            case recentTransactions.featureTypeMarker:
            case recentTransactions.featureTypeCluster:
              if (!this.reportVueMounted) {  // including when this.reportVueMounted is undefined
                EventBus.$on("realnetReportMounted", () => {
                  EventBus.$emit("get_transactions", recentTransactions.getRecentTransactions(clickedFeature));
                });
                this.reportVueMounted = true;
              }
              EventBus.$emit("get_transactions", recentTransactions.getRecentTransactions(clickedFeature));
              router.push({ name: "transaction" });
              break;
            default:
              console.log("Clicked on", clickedFeature.featureType);
          }
        } else {
          var icoordinate = evt.coordinate;
          this.MapOnClick(icoordinate);
        }
      });
    },
    initPopup() {
      // Vienna marker
      popup = new Overlay({
        positioning: "bottom-center",
        element: this.$refs.popup,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
        //offset: [-140 + 120, 0],
      });
      console.log('popup',popup);
      map.addOverlay(popup);
    },
    initData() {},
    popupcloser() {
      popup.setPosition(undefined);
      //   closer.blur();
      return false;
    },
    MapOnClick(coordinate) {
      //pop up
      //var hdms = toStringHDMS(toLonLat(coordinate));

      if( containsCoordinate(App_extent,coordinate) ) {

      




      var apopupContent = "";
      //this.popupContent=""
      var a_address = "";

      // Get the extent for map boundaries
     var extent =map.getView().calculateExtent(map.getSize());
     console.log("Extent, LL_x: " + extent[0]); //left LL_x: -13708573.09907316
     console.log("Extent, LL_y: " + extent[1]); //bottom LL_y: 6317899.993763422
     console.log("Extent, UR_x: " + extent[2]); //right UR_x: -13706121.72827147
     console.log("Extent, UR_y: " + extent[3]); //top UR_y: 6319568.366776173








      var url = mapconfig.bcparcelmap
        .getSource()
        .getFeatureInfoUrl(
          coordinate,
          map.getView().getResolution(),
          map.getView().getProjection(),
          {
            INFO_FORMAT: "application/json",
            propertyName: "PARCEL_FABRIC_POLY_ID,PARCEL_NAME,PIN,PID_NUMBER,PID,OWNER_TYPE,PARCEL_CLASS,PLAN_NUMBER,SHAPE",
          }
        );
      console.log("url:" + url);
      url = proxyurl1 + url;

      // Ref: https://stackoverflow.com/questions/54042152/uncaught-in-promise-typeerror-cannot-set-property-playername-of-undefined-a/54042367
      let that = this; // this is needed as 'this' is not understood after axio.get.then(function)
      axios.get(url).then(function(response) {
        console.log(response);
        if (response.data.features.length > 0) {

          var feature = response.data.features[0];
          that.selectedParcel=feature
          that.selectedParcel_id=feature.properties.PARCEL_FABRIC_POLY_ID

          that.checkBookmarked()
          

          var g = feature.geometry; //get geometry of a featyre in your vector layer
          var vertices = g.coordinates; //get vertices as array of POINT geometry in an array
          

          map.getLayers().forEach((layer) => {
            if (layer && layer.get("name") === "Selected Location") {
              //alert("Remove Vector Layer");
              map.removeLayer(layer);
            }
          });
          var parcelcolor = "blue";
          if (!feature.properties.PID) {
            //TO DO: GET ADDRESS from GEOCODER.CA e.g. http://geocoder.ca?latt=49.2631005&longt=-122.8852747&reverse=1&geoit=XML
            parcelcolor = "red";
          }
          else {
            if(feature.properties.PARCEL_NAME=="Building Strata") 
            {
              parcelcolor="green" // Build Strata but with a PID (one of the units) provided
            }
          }

          that.lonlat = olProj.transform(coordinate, "EPSG:3857", "EPSG:4326");
          var lon = that.lonlat[0];
          var lat = that.lonlat[1];
          // Use Bing Map API
          //var BingMapKey =
          //  "AnBk-_PACFpRa99Sgep3ZAS0y1co3qI0NvMcEJTWURpLTOof56yvVxjKQ15JhB5m";
          //var BingMapAPIURL =
          //  "https://dev.virtualearth.net/REST/v1/Locations/";
          
          var IQURL="https://us1.locationiq.com/v1/reverse.php?key=6cdf16a403410b&lat=" + lat +" &lon=" +lon +"&addressdetails=1&format=json";


          if (!feature.properties.PID || feature.properties.PID=="Building Strata") {


            axios
           //   .get(BingMapAPIURL + lat + "," + lon + "?key=" + BingMapKey)
              .get(proxyurl1+IQURL)
              .then(function(response) {
                console.log('Geocoding address',response);
                var mapAddress=response.data.address
                if (mapAddress){
                  var road="";
                  var house_number="";
                  var name="";
                  if (mapAddress.road) {road=mapAddress.road};
                  if (mapAddress.house_number){house_number=mapAddress.house_number};
                  if (mapAddress.name){name=mapAddress.name};
                  
                  mapAddress=house_number + " " + road;
                  
                //  if (name.length>0) {mapAddress=name};
                  a_address=mapAddress
                  that.selectedAddress=a_address

                };
                /* for Bing Map API
                var result = response.data;
                let a_address =
                  result.resourceSets[0].resources[0].address.addressLine;
                */
                apopupContent = `<p>${a_address} <i>(to be confirmed)</i></p>`;
                that.popupContent = apopupContent;
                popup.setPosition(coordinate);
              });
          } else {
            
            var props = feature.properties;
            var PID = props.PARCEL_NAME;

            var aPID =
              PID.substr(0, 3) + "-" + PID.substr(3, 3) + "-" + PID.substr(6);
            that.GetParcelInfoByPID(PID).then(function() {
              if (that.$data.xProperty){
              console.log('$data.xProperty',that.$data.xProperty)
              if (that.$data.xProperty) {
              console.log("aProperty.Address:", that.$data.xProperty.Address);
              apopupContent = `<p align="left">${
                that.$data.xProperty.Address
              }</p><p align="left">${that.$data.xOwner.FirstName1.concat(
                " ",
                that.$data.xOwner.LastName1
              )}</p><p align="left">${that.$data.xOwner.Company}</p`;
              
              that.popupContent = apopupContent;
              popup.setPosition(coordinate);
              that.selectedAddress=that.$data.xProperty.Address
              EventBus.$emit("get_coordinate",that.lonlat)

              console.log("transform coordinate:",that.lonlat)
              
              EventBus.$emit("get_property", that.$data.xProperty);
              EventBus.$emit("get_owner", that.$data.xOwner);
              EventBus.$emit("get_pid", that.$data.xPID);
              EventBus.$emit(
                "get_db-contactid",
                that.$data.xOwner.db + "-" + that.$data.xOwner.id_contacts
              );
              that.ownerinfo=that.$data.xOwner
              that.propinfo=that.$data.xProperty
              }
              }
              else 
              {
                axios
                .get(proxyurl1+IQURL)
                .then(function(response) {
                  console.log('Geocoding address2',response);
                  var mapAddress=response.data.address
                  if (mapAddress){
                    var road="";
                    var house_number="";
                    var name="";
                    if (mapAddress.road) {road=mapAddress.road};
                    if (mapAddress.house_number){house_number=mapAddress.house_number};
                    if (mapAddress.name){name=mapAddress.name};
                    
                    mapAddress=house_number + " " + road;
                    
                  //  if (name.length>0) {mapAddress=name};
                    a_address=mapAddress
                    that.selectedAddress=a_address
                  }
                  EventBus.$emit("get_coordinate",that.lonlat)
                  console.log("transform coordinate:",that.lonlat)
                  EventBus.$emit("get_property", that.$data.xProperty);
                  EventBus.$emit("get_owner", that.$data.xOwner);
                  EventBus.$emit("get_pid", null);
                  EventBus.$emit("get_plannumber", feature.properties.PLAN_NUMBER);
                  that.ownerinfo=that.$data.xOwner
                  that.propinfo=that.$data.xProperty
                  that.popupContent=`<p align="left">${a_address} <i>(to be confirmed)</i></p><p align="left">${feature.properties.PLAN_NUMBER}</p>`
                  popup.setPosition(coordinate);
                   })
              }
            });
          } // end if else (feature.properties.PID)
          var styles = [
            new Style({
              stroke: new Stroke({
                color: parcelcolor,
                width: 3,
              }),
              fill: new Fill({
                color: "rgba(0, 0, 255, 0.1)",
              }),
            }),
          ];
          console.log("vertices",vertices)
          
          var polygon = new Polygon(vertices);
          console.log("polygon",polygon)
          
         that.selectedCentre=polygon.getInteriorPoint()
                   console.log("coordinate",coordinate)
          console.log("aCenter",that.selectedCentre)
         // fix for parcel selected but cannot get blue polygon redraw. e.g. 25450 lougheed Maple Ridge
         if (!that.selectedCentre.flatCoordinates[0]) {
           var aPoint=new Point(coordinate)
           that.selectedCentre=aPoint
           console.log("aPoint",aPoint)
         }

          //polygon.transform('EPSG:4326', 'EPSG:3857');
          // Create feature with polygon.
          var afeature = new Feature(polygon);
          // Create vector source and the feature to it.
          var vectorSource = new Vector();
          vectorSource.addFeature(afeature);
          // Create vector layer attached to the vector source.
          var vectorLayer = new VectorLayer({
            name: "Selected Location",
            source: vectorSource,
            style: styles,
          });
          // Add the vector layer to the map.
          map.addLayer(vectorLayer);
        } // end of if (feature)
      }); // end of get(URL)
    }  //end of if perry extent
    }, // end of MapOnClick
    fileUpload: function (file, path = '/', callback) {
    if (!file) {
      alert('Please select a file to upload')
      return
    }
    let extension=file.name.split('.').pop()
    let newfilename=this.currentUser.uid + "." + extension
    let newfile=new File([file],newfilename)
    console.log("newfilename",newfile.name)
    let uploadTask = fb.storage.ref().child(`images/${newfile.name}`).put(newfile)
    uploadTask.on('state_changed', function (snapshot) {
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log('Upload is ' + progress + '% done')
    }, error => {
      callback(error, null)
    }, success => {
      let downloadURL = uploadTask.snapshot.ref.getDownloadURL()
      callback && callback(null, downloadURL)
    })
  },
  }, // end of methods
  mounted() {
    this.$nextTick(() => {
      this.init(),
      EventBus.$on("delete_bookmark_parcel" ,(data)=>{
          console.log("Need to delete this parcel in bookmarklayer:",data)
          this.deleteParcelonBookmarkLayer(data)

      }),
      EventBus.$on("go2map",(data)=>{
        var polygonCoord=data
        console.log("get go2map",polygonCoord)
        this.MapOnClick(polygonCoord)
        map.getView().setCenter(polygonCoord)

      })
    });
  },
};
</script>

<style >
#mapView {
  height:97vh;
  width: 99vw;
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: -1;

}

.ol-popup {
  position: absolute;
  background-color: #fff;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 250px;
  font-size: 14px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: #fff;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.satellite {
  left: 0.5em;
  top: 7.5em;
}

.bookmark {
  left: 0.5em;
  top: 10.0em;
}

.locate {
  top: 12.5em;
  left: 0.5em;
}

.recentTrans {	
  top: 15.0em;
  left: 0.5em;
}

.recentTrans-filter {
  top: 15.0em;
  left: 2.85em;
}

.ol-geocoder.gcd-txt-container {
  width: 15em;
  left: 3.3em;
}

.ol-geocoder .gcd-txt-control {
  height: 2.8em;
  top: 1.1em;
}

.mainmenu {
  position: absolute;
  top: 50%;
  left: 0.1em;
  margin: 3px 0;
}

.testbutton {
  position: absolute;
  top: 70%;
  left: 0.1em;
  margin: 3px 0;
}

.avatar {
  vertical-align: middle;
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
</style>
